import search from "../../assets/images/searchIcon.png";

const FilterModal = ({
  title = "",
  data = [],
  className = "",
  clearBtn = false,
  searchQuery = "",
  clearBtnText = "",
  activeFilters = [],
  isSearchable = false,
  onChange = () => { },
  onClickClear = () => { },
  onSearchChange = () => { }
}) => {

  const filteredData = data.filter(el => el.toLowerCase().includes(searchQuery.toLowerCase()));

  return (
    <div className={`filterModal ${className}`}>
      <div className="filterModalHeader fw-bold">{title}</div>
      <div className="filterModalHr"></div>
      <div className="filterModalContainer">
        {isSearchable ? (
          <div className="filter-doctor-search">
            <div>
              <div><img src={search} alt="" /></div>
              <div>
                <input
                  type="text"
                  id="filter-search"
                  placeholder="Search"
                  onChange={(e) => onSearchChange(e.target.value)}
                  value={searchQuery}
                />
              </div>
            </div>
          </div>
        ) : null}

        {filteredData.map((el, i) => (
          <div className="filterModalBox d-flex" key={i}>
            <div>{el}</div>
            <div className="checkbox-wrapper">
              <input
                type="checkbox"
                name={el}
                checked={activeFilters.includes(el)}
                className="cursor-pointer"
                onChange={onChange}
              />
            </div>
          </div>
        ))}
      </div>
      {clearBtn ?
        <div className="filterFooterBtn justify-content-center pt-3">
          <button
            onClick={onClickClear}
            className="me-2 align-items-center w-auto"
          >
            {clearBtnText}
          </button>
        </div> : <></>
      }
    </div>
  )
}

export default FilterModal