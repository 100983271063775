import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import "./index.scss";
import BannerSuccessModal from "../../modal/bannerSucessModal";
// import RemoveModal from "../../modal/removeModal";

const allFields = {
  Doctor_Register_Banner: "Doctor Register Banner",
  Doctor_Register_Form_Banner: "Doctor Register Form Banner",
  retail_Pharmacy_Banner: "Retail Pharmacy Banner",
  retail_Pharmacy_Form_Banner: "Retail Pharmacy Form Banner",
  health_Care_Banner: "Health Care Banner",
  health_Care_Form_Banner: "Health Care Form Banner",
  other_Register_Form_Banner: "Other Registration Form Banner",
  career_Banner: "Career Banner",
  insta_doc_Banner: "InstaDoc Banner",

  Doctor_Register_Banner_mob: "Doctor Register Banner",
  Doctor_Register_Form_Banner_mob: "Doctor Register Form Banner",
  retail_Pharmacy_Banner_mob: "Retail Pharmacy Banner",
  retail_Pharmacy_Form_Banner_mob: "Retail Pharmacy Form Banner",
  health_Care_Banner_mob: "Health Care Banner",
  health_Care_Form_Banner_mob: "Health Care Form Banner",
  other_Register_Form_Banner_mob: "Other Registration Form Banner",
  career_Banner_mob: "Career Banner",
  insta_doc_Banner_mob: "InstaDoc Banner",
};

const BannerImages = () => {
  const [imagesList, setImagesList] = useState({});
  const [success, setSuccess] = useState({ isActive: false, action: '' });
  const [isUpload, setIsUpload] = useState(true)
  const [removeModal, setRemoveModal] = useState({ isActive: false, isRemove: false })
  const [id, setId] = useState("");
  const [count, setCount] = useState(0);

  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_API_URL}banner-images/list`)
      .then((res) => {
        const {
          Doctor_Register_Banner = "",
          Doctor_Register_Form_Banner = "",
          retail_Pharmacy_Banner = "",
          retail_Pharmacy_Form_Banner = "",
          health_Care_Banner = "",
          health_Care_Form_Banner = "",
          other_Register_Form_Banner = "",
          career_Banner = "",
          insta_doc_Banner = "",
          Doctor_Register_Banner_mob = "",
          Doctor_Register_Form_Banner_mob = "",
          retail_Pharmacy_Banner_mob = "",
          retail_Pharmacy_Form_Banner_mob = "",
          health_Care_Banner_mob = "",
          health_Care_Form_Banner_mob = "",
          other_Register_Form_Banner_mob = "",
          career_Banner_mob = "",
          insta_doc_Banner_mob = ""
        } = res.data.rows[0];
        setImagesList({
          Doctor_Register_Banner,
          Doctor_Register_Form_Banner,
          retail_Pharmacy_Banner,
          retail_Pharmacy_Form_Banner,
          health_Care_Banner,
          health_Care_Form_Banner,
          other_Register_Form_Banner,
          career_Banner,
          insta_doc_Banner,

          Doctor_Register_Banner_mob,
          Doctor_Register_Form_Banner_mob,
          retail_Pharmacy_Banner_mob,
          retail_Pharmacy_Form_Banner_mob,
          health_Care_Banner_mob,
          health_Care_Form_Banner_mob,
          other_Register_Form_Banner_mob,
          career_Banner_mob,
          insta_doc_Banner_mob
        });
        setId(res.data.rows[0]._id);
        setCount(res.data.count);
      });
  }, [count]);

  const onSubmit = async () => {
    if (count) {
      axios
        .postForm(
          `${process.env.REACT_APP_API_URL}banner-images/update/${id}`,
          imagesList
        )
        .then((response) => {
          if (response.data?._id) {
            setSuccess({ isActive: true, action: 'Updated' });
          } else {
            alert("Fail");
          }
        }).catch((err) => {
          console.log('err - ', err);
        })
    } else {
      await axios
        .postForm(
          `${process.env.REACT_APP_API_URL}banner-images/create`,
          imagesList
        )
        .then((response) => {
          if (response.data?._id) {
            setSuccess({ isActive: true, action: 'Posted' });
          } else {
            alert("Fail");
            console.log("failed...");
          }
        }).catch((err) => {
          console.log('err - ', err);
        });
    }
    setIsUpload(true)

  };

  const handleInputChange = (e) => {
    setImagesList({ ...imagesList, [e.target.name]: e.target.files[0] });
    setIsUpload(false)
  };

  const getsrcValue = (value) => {
    if (typeof value === "string" && value.length)
      return process.env.REACT_APP_API_URL + "images/" + value;
    return typeof value === "object" ? URL.createObjectURL(value) : " ";
  };

  const removeImage = async (key, value) => {

    const fields = { ...imagesList, [key]: '' }
    setIsUpload(false);
    setImagesList(fields);

  }
  const {
    Doctor_Register_Banner = "",
    Doctor_Register_Form_Banner = "",
    retail_Pharmacy_Banner = "",
    retail_Pharmacy_Form_Banner = "",
    health_Care_Banner = "",
    health_Care_Form_Banner = "",
    other_Register_Form_Banner = "",
    career_Banner = "",
    insta_doc_Banner = "",

    Doctor_Register_Banner_mob = "",
    Doctor_Register_Form_Banner_mob = "",
    retail_Pharmacy_Banner_mob = "",
    retail_Pharmacy_Form_Banner_mob = "",
    health_Care_Banner_mob = "",
    health_Care_Form_Banner_mob = "",
    other_Register_Form_Banner_mob = "",
    career_Banner_mob = "",
    insta_doc_Banner_mob = ""
  } = imagesList;

  return (
    <div className="healtharticle">
      <div className="healtharticle-title">
        <Link
          to="/settings-social-media-link"
          style={{
            textDecoration: "none",
            color: "black",
            fontSize: "15px",
          }}
        >
          <span style={{ paddingLeft: "5%" }}>Settings</span>
        </Link>
        <span style={{ fontSize: "15px", paddingLeft: "1%" }}>&#62;</span>
        <Link
          to=""
          style={{
            textDecoration: "none",
            color: "black",
            fontSize: "15px",
            paddingLeft: "0.2%",
            width: "20%",
          }}
        >
          <span style={{ paddingLeft: "6%" }}>Banner Images</span>
        </Link>
      </div>
      <div className="carr-dep-form">
        <div className="row car-dep-form g-5">
          {/* ----one---- */}
          {Object.entries({
            Doctor_Register_Banner,
            Doctor_Register_Form_Banner,
            retail_Pharmacy_Banner,
            retail_Pharmacy_Form_Banner,
            health_Care_Banner,
            health_Care_Form_Banner,
            other_Register_Form_Banner,
            career_Banner,
            insta_doc_Banner,
          }).map(([key, value], inx) => {

            return (
              <div className="col-3 upload-img" key={inx}>
                <div>
                  <label className="mb-3  car-text">{allFields[key]}</label>
                </div>
                {value ? (
                  <img
                    src={getsrcValue(value)}
                    style={{
                      width: "200px",
                      height: "50px",
                      marginTop: "2%",
                      marginBottom: "5%",
                    }}
                    alt="image showing"
                  />
                ) : null}
                <input
                  className="form-control-file custom-file-input"
                  type="file"
                  id={key}
                  name={key}
                  onChange={handleInputChange}
                  required={false}
                />
                {value &&
                  <button className="btn btn-danger ms-2" onClick={() => removeImage(key, value)}>Remove</button>}
              </div>
            );
          })}
        </div>
        <hr />
        <div> Mobile Banners </div>
        <div className="row car-dep-form g-5">
          {Object.entries({
            Doctor_Register_Banner_mob,
            Doctor_Register_Form_Banner_mob,
            retail_Pharmacy_Banner_mob,
            retail_Pharmacy_Form_Banner_mob,
            health_Care_Banner_mob,
            health_Care_Form_Banner_mob,
            other_Register_Form_Banner_mob,
            career_Banner_mob,
            insta_doc_Banner_mob
          }).map(([key, value], inx) => (
            <div className="col-3 upload-img" key="inx">
              <div>
                <label className="mb-3 car-text">{allFields[key]}</label>
              </div>
              {value ? (
                <img
                  src={getsrcValue(value)}
                  style={{
                    width: "200px",
                    height: "50px",
                    marginTop: "2%",
                    marginBottom: "5%",
                  }}
                  alt="image showing"
                />
              ) : null}
              <input
                className="form-control-file custom-file-input"
                type="file"
                id={key}
                name={key}
                onChange={handleInputChange}
                required={false}
              />
              {value &&
                <button className="btn btn-danger ms-2" onClick={() => removeImage(key, value)}>Remove</button>}
            </div>
          ))}
        </div>
        <div className="car-dep-form-button mb-5 mt-5">
          <button type="submit" className="btn btn-success" disabled={isUpload} onClick={() => onSubmit()}>SUBMIT</button>
        </div>
      </div>
      {success?.isActive && (
        <BannerSuccessModal
          name="Banner Images"
          post={success?.action}
          setSuccess={() => setSuccess()}
        />
      )}
      {/* {
        removeModal?.isActive && (
          <RemoveModal
            name="Banner Images"
            post={success?.action}
            setRemoveModal={() => setRemoveModal()}
          />
        )
      } */}
    </div>
  );
};

export default BannerImages;
