import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import bg_image from "../../assets/images/bg_image.svg";
import loginHealthsyImg from "../../assets/images/login_healthsy_img.svg";
import "./account.scss";
// import logo from "../../assets/images/login_logo.PNG";
// import banner_logo from "../../assets/svg/logo.svg";
// import eye from "../../assets/svg/eye-icon.svg";
// import hideEye from "../../assets/images/hide.png";

const Login = () => {
  const navigate = useNavigate();

  const [loginBtnBg, setLoginBtnBg] = useState("#FAE8EF");
  const [showPassword, setShowPassword] = useState(null);
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);
  const [data, setData] = useState([]);
  const [eyeIcon, seteyeIcon] = useState(true);
  const [error, setError] = useState("");
  const [nameError, setNameError] = useState("");
  const [passwordError, setPasswordError] = useState("");


  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}admin-panel/list`)
      .then((res) => {
        setData(res.data);
      });
    if (localStorage.getItem("auth")) {
      navigate("/");
    }
    navigate("/login")
  }, []);

  useEffect(() => {
    var cookiePass = JSON.parse(localStorage.getItem("CookiePassword"));
    var cookieUser = JSON.parse(localStorage.getItem("CookieUser"));
    setUsername(cookieUser);
    setPassword(cookiePass);
  }, []);

  useEffect(() => {
    if (username && password) {
      setLoginBtnBg("#CB1B5B")
    } else {
      setLoginBtnBg("#FAE8EF")

    }
  }, [username, password])

  const handleLogin = async () => {
    setError("")
    setNameError("")
    setPasswordError("")
    if (username === null || username === "") {
      setNameError("Please provide a user name")
    }
    if (password === null || password === "") {
      setPasswordError("Please provide a password")
    } else if (username && password) {
      data.map((e) => {
        if (username != e.username || password != e.password) {
          return setError("Please Enter a valid credentials");
        }
      });
      data.map(async (e) => {
        if (e.username === username && e.password === password) {
          const response = await axios.post(`${process.env.REACT_APP_API_URL}admin-panel/login`, {
            username,
            password,
          });
          const { token } = response.data;

          if (token) {
            localStorage.setItem("authToken", token);
          }
          navigate("/");
          window.location.reload();
        }
      });
    }
  };

  const handlePassword = () => {
    seteyeIcon(!eyeIcon);
    setShowPassword(!showPassword);
  };

  const onChangeInput = (e, type) => {
    if (type === "username") {
      setUsername(e.target.value);
    } else {
      setPassword(e.target.value);
    }
  }

  const handleCookieData = () => {
    data.map((e) => {
      if (e.username === username && e.password === password) {
        localStorage.setItem("CookiePassword", JSON.stringify(password));
        localStorage.setItem("CookieUser", JSON.stringify(username));
      }
    });
  };

  return (
    <div className="adminpanellogin">
      <div className="adminpanel-left">
        <div className="position-absolute adminpanel-login-image">
          <img className="adminpanel-login-img" src={bg_image} alt="" />
          <div className="position-absolute">
            <img className="adminpanel-login-healthsyImg" src={loginHealthsyImg} alt="" />
          </div>
        </div>
        <div className="adminpanel-left-box position-relative">
        </div>
      </div>
      <div className="adminpanel-right">
        <div className="adminpanel-right-box">
          <div className="adminpanel-login-title">Welcome to HealthSy
            Subadmin Panel</div>
          <div className="adminpanel-right-title">
            <div>Login to access your account</div>
          </div>
          {error && (
            <div style={{ marginBottom: "10px", color: "red" }}>{error}</div>
          )}
          <div className="adminpanel-right-form">
            <div className="adminpanel-right-username">
              <div>
                <label className="pure-material-textfield-outlined">
                  <input
                    className="login-username"
                    style={{ background: "#fff" }}
                    type="text"
                    onChange={(e) => onChangeInput(e, "username")}
                    value={username}
                    placeholder=""
                  />
                  <span className="adminpanel-placeholder">User Name</span>
                </label>
              </div>
            </div>
            <p className="text-danger">{nameError}</p>
            <div className="adminpanel-right-password">
              <div>
                <div>
                  <label className="pure-material-textfield-outlined">
                    <input
                      className="login-password"
                      type={showPassword ? "text" : "password"}
                      onChange={onChangeInput}
                      value={password}
                      placeholder=""
                    />
                    <span className="adminpanel-input-placeholder">Password</span>
                  </label>
                </div>
                {/* <div
                    className="hide position-absolute"
                    style={{ width: "16px", height: "16px" }}
                  >
                    {eyeIcon ? (
                      <img
                        src={eye}
                        onClick={handlePassword}
                        style={{ width: "100%" }}
                      />
                    ) : (
                      <img
                        src={hideEye}
                        onClick={handlePassword}
                        style={{ width: "100%" }}
                      />
                    )}
                  </div> */}
              </div>
            </div>
            <p className="text-danger">{passwordError}</p>
            <div className="adminpanel-right-forgot">
              <div className="check">
                <div className="check-box">
                  <label className="admin-container">
                    <input
                      id="cb1"
                      type="checkbox"
                      value="checkboxCookie"
                      onChange={handleCookieData}
                    />
                    <span className="admin-checkmark"></span>
                  </label>
                </div>
                <div className="adminpanel-loggedIn">Keep me Logged In</div>
              </div>
              <div>Forgot Password?</div>
            </div>
            <div className="adminpanel-right-button">
              <button
                onClick={handleLogin}
                style={{ background: loginBtnBg }}
              >
                Log in
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;