import React from "react";

const FilterBooleanModal = ({ title, data, className, activeFilters, onChange, statusValue }) => {
  return (
    <div className={`filterModal ${className}`}>
      <div className="filterModalHeader fw-bold">{title}</div>
      <div className="filterModalHr"></div>
      <div className="filterModalContainer">
        {data.map((label, inx) => {
          const selectedValue = statusValue.find(item => item.label === label)?.value;

          const isSelected = activeFilters.includes(selectedValue);

          return (
            <div className="filterModalBox d-flex">
              <div className="checkbox-wrapper">
                <label key={label}>
                  <input
                    type="checkbox"
                    name={label}
                    value={selectedValue}
                    checked={isSelected}
                    className="cursor-pointer"
                    onChange={onChange}
                  />
                  <span className="custom-checkbox-input"></span>
                  {label}
                </label>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FilterBooleanModal