import React, { useState } from "react";
import "./index.scss";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import SucessModal from "../../../modal/SucessModal";
import { useEffect } from "react";

const SocialForm = () => {
  const {
    register,
    getValues,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [success, setSuccess] = useState(false);
  const [editSuccess, setEditSuccess] = useState(false);
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [id, setId] = useState("");
  const [count, setCount] = useState(0);

  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_API_URL}social-media-links/list`)
      .then((res) => {
        setData(res.data.rows);
        setId(res.data.rows[0]._id);
        setCount(res.data.count);
        Object.keys(res.data.rows[0]).map((x) => {
          setValue(x, res.data.rows[0][x]);
        });
      });
  }, [count]);

  const onSubmit = (fields) => {
    if (count === 0) {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}social-media-links/create`,
          fields
        )
        .then((response) => {
          if (response.data?._id) {
            setSuccess(true);
          } else {
            alert("Fail");
            console.log("failed...");
          }
        });
    } else if (count === 1) {
      let fields = getValues();
      axios
        .patch(
          `${process.env.REACT_APP_API_URL}social-media-links/update/${id}`,
          {
            linkedin: fields.linkedin,
            facebook: fields.facebook,
            instagram: fields.instagram,
            twitter: fields.twitter,
            youtube: fields.youtube,
          }
        )
        .then((response) => {
          if (response.data?._id) {
            setEditSuccess(true);
          } else {
            alert("Fail");
          }
        });
    }
  };

  return (
    <>
      {/* <Home> */}
      {/* <div
        onClick={() => navigate("/settings-social-media-link")}
        className="rose back-button"
      >
        <img src={backRed} alt="" />
        <span className="rose-back">Back</span>
      </div> */}
      <div className="healtharticle">
        <div className="healtharticle-title">
          <Link
            to="/settings-social-media-link"
            style={{
              textDecoration: "none",
              color: "black",
              fontSize: "15px",
            }}
          >
            <span style={{ paddingLeft: "5%" }}>Settings</span>
          </Link>
          <span style={{ fontSize: "15px", paddingLeft: "1%" }}>></span>
          <Link
            to=""
            style={{
              textDecoration: "none",
              color: "black",
              fontSize: "15px",
              paddingLeft: "0.2%",
              width: "20%",
            }}
          >
            <span style={{ paddingLeft: "6%" }}>Add Social Media Links</span>
          </Link>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="carr-dep-form">
          <div className="row car-dep-form">
            <div className="col-6">
              <label
                className="car-dep-form-txt mb-2"
                style={{ color: "#4B4853" }}
                htmlFor=""
              >
                LinkedIn URL {/* <span className="star">*</span> */}
              </label>
              <input
                className="form-control w-75"
                type="text"
                name=""
                id="type"
                {...register("linkedin", { required: false })}
                placeholder="Type here..."
              />
              {/* {errors.type && (
                  <div className={`invalid-feedback d-block`}>
                    Please Enter LinkedIn URL
                  </div>
                )} */}
            </div>

            <div className="col-6">
              <label
                className="car-dep-form-txt mb-2"
                style={{ color: "#4B4853" }}
                htmlFor=""
              >
                Facebook URL {/* <span className="star">*</span> */}
              </label>
              <input
                className="form-control w-75"
                type="text"
                name=""
                id="type"
                {...register("facebook", { required: false })}
                placeholder="Type here..."
              />
              {/* {errors.type && (
                  <div className={`invalid-feedback d-block`}>
                    Please Enter Facebook URL
                  </div>
                )} */}
            </div>

            <div className="col-6 mt-5">
              <label
                className="car-dep-form-txt mb-2"
                style={{ color: "#4B4853" }}
                htmlFor=""
              >
                Instagram URL {/* <span className="star">*</span> */}
              </label>
              <input
                className="form-control w-75"
                type="text"
                name=""
                id="type"
                {...register("instagram", { required: false })}
                placeholder="Type here..."
              />
              {/* {errors.type && (
                  <div className={`invalid-feedback d-block`}>
                    Please Enter Instagram URL
                  </div>
                )} */}
            </div>

            <div className="col-6 mt-5">
              <label
                className="car-dep-form-txt mb-2"
                style={{ color: "#4B4853" }}
                htmlFor=""
              >
                Twitter URL {/* <span className="star">*</span> */}
              </label>
              <input
                className="form-control w-75"
                type="text"
                name=""
                id="type"
                {...register("twitter", { required: false })}
                placeholder="Type here..."
              />
              {/* {errors.type && (
                  <div className={`invalid-feedback d-block`}>
                    Please Enter Twitter URL
                  </div>
                )} */}
            </div>

            <div className="col-6 mt-5">
              <label
                className="car-dep-form-txt mb-2"
                style={{ color: "#4B4853" }}
                htmlFor=""
              >
                Youtube URL
              </label>
              <input
                className="form-control w-75"
                type="text"
                name=""
                id="type"
                {...register("youtube", { required: false })}
                placeholder="Type here..."
              />
              {/* {errors.type && (
                  <div className={`invalid-feedback d-block`}>
                    Please Enter Twitter URL
                  </div>
                )} */}
            </div>
          </div>

          <div className="car-dep-form-button">
            <button type="submit">SUBMIT</button>
          </div>
        </form>
      </div>
      {/* </Home> */}
      {success && (
        <SucessModal
          page={"/settings-social-media-form"}
          name="Social Media Links"
          post="posted"
        />
      )}

      {editSuccess && (
        <SucessModal
          page={"/settings-social-media-form"}
          name="Social Media Links"
          post="updated"
        />
      )}
    </>
  );
};

export default SocialForm;
