import axios from "axios";
import Moment from "moment";
import React, { useEffect, useState } from "react";
import { AiOutlineBackward, AiOutlineForward } from "react-icons/ai";
import { FaAngleRight } from "react-icons/fa";
import LoadingOverlay from "react-loading-overlay";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

import ReactPaginate from "react-paginate";
import apiCall from "../../../api";
import add from "../../../assets/images/buttonAdd.png";
import featuredImage from "../../../assets/images/featured-image.PNG";
import search from "../../../assets/images/searchIcon.png";
import edit from "../../../assets/svg/edit.svg";
import inactive from "../../../assets/svg/inactive.svg";
import { dateOfPublishing, filterBlogsOptions, spotlight, status, statusValue, trendingBlogs } from "../../../constants";
import DoctorsFilterOptions from "../../Common/doctorsFilterOptions";
import FilterBooleanModal from "../../Common/filterBooleanModal";
import FilterModal from "../../Common/filterModal";
import FilterRootModal from "../../Common/filterRootModal";
import { SortByModel } from "../../Common/filtersRootModals";
import Deletepopup from "../../modal/Deletepopup";
import "./healtharticle.scss";

const HealthArticle = () => {
  const [pages, setPages] = useState(0);
  const navigate = useNavigate();
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [sort, setSort] = useState("newest");
  const [sortshow, setSortShow] = useState(false);
  const [sortName, setSortName] = useState("Sort by");
  const [filtershow, setFilterShow] = useState(false);
  const [records, setRecords] = useState([]);
  const [del, setDel] = useState(false)
  const [id, setId] = useState("")
  const [isLoading, setIsLoading] = useState(true);
  const [pageOffset, setPageOffset] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [textcolor, setTextColor] = useState(null);
  const [selectedSort, setSelectedSort] = useState(null);

  const [menu, setMenu] = useState([]);
  const [menuData, setMenuData] = useState([]);
  const [category, setCategory] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [statusData, setStatusData] = useState([]);
  const [trendingBlog, setTrendingBlog] = useState([]);
  const [topicOfMonth, setTopicOfMonth] = useState([]);
  const [datePublishing, setDatePublishing] = useState("");

  useEffect(() => {
    getCategory();
    getHealthArticle();
  }, [])

  useEffect(() => {
    handlePageChange({ selected: 0 })
  }, [searchText])

  useEffect(() => {
    list_api_call();
  }, [menu, category, status, dateOfPublishing, skip, limit, sort, searchText]);

  const getHealthArticle = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}health-article-menu/list`)
      .then((res) => {
        setMenu(res.data.rows.map((data) => data?.name));
      });
  };

  const getCategory = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}health-article-category/list`)
      .then((res) => {
        setCategory(res.data.rows.map((data) => data?.category));
      });
  };

  const handleActive = (id, status) => {
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}health-article/update/active/${id}`,
        {
          active: !status,
        }
      )
      .then((result) => {
        list_api_call();
      });
  };

  const list_api_call = async (clearFilter = false) => {
    let filterValues = {
      menu: menuData,
      category: categoryData,
      dateOfPublishing: datePublishing,
    }
    if (statusData?.length === 1) {
      filterValues["status"] = statusData;
    }
    if (topicOfMonth?.length === 1) {
      filterValues["spotlight"] = topicOfMonth;
    }
    if (trendingBlogs?.length) {
      filterValues["trendingBlogs"] = trendingBlog;
    }
    const result = await apiCall(`health-article/admin-blog-list`, "POST",
      {
        values: clearFilter ? {} : filterValues,
        searchText,
        skip: skip,
        limit: limit,
        sort: sort,
      })
    setIsLoading(false)
    setRecords(result.data.rows);
    setPages(Math.ceil(result.data.count / limit));
  };

  const [debounceTimer, setDebounceTimer] = useState([]);

  const onChangeSearch = (e) => {
    const value = e.target.value;
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }
    const timer = setTimeout(() => {
      setSearchText(value);
    }, 500);
    setDebounceTimer(timer);
  };

  const handleNewHealthArticle = () => {
    navigate("/healtharticleaddnew");
  };

  const handleDelete = (id) => {
    fetch(`${process.env.REACT_APP_API_URL}health-article/delete/${id}`, {
      method: "DELETE",
    }).then((result) => {
      result.json().then((res) => {
        list_api_call();
        setDel(false)
      });
    });
  };

  const handleMenu = () => {
    navigate("/healtharticlemenu");
  };
  const handleHealthsyStories = () => {
    navigate("/healthsystories");
  }

  const handleView = (id) => {
    navigate(`/healthArticleView/${id}`);
  };

  const handleEdit = (id) => {
    navigate(`/healtharticleEdit/${id}`)
  }

  const handlePageChange = (event) => {
    setSkip(event.selected * limit)
    setPageOffset(event.selected);
  };

  const handleSortClick = (sortOption, colorCode) => {
    setSelectedSort(sortOption);
    setTextColor(colorCode);
  };

  const handleApplyClick = () => {
    if (selectedSort) {
      setSort(selectedSort);
      setSortShow(false);
    }
  };

  const onSearchChange = (query) => {
    setSearchQuery(query);
  };

  const onCategoryChange = (e) => {
    const categoryName = e.target.name;

    if (categoryData.includes(categoryName)) {
      setCategory(categoryData.filter((ele) => ele !== categoryName));
    } else {
      setCategoryData((prev) => [...prev, categoryName]);
    }
  };

  const handleClear = () => {
    setTrendingBlog([]);
  };

  return (
    <>
      {/* <Home> */}
      <div className="healtharticle">
        <div className="healtharticle-container ms-2">
          <div className="healtharticle-title ms-2">
            <Link
              to="/dashboard"
              style={{
                textDecoration: "none",
                color: "black",
                fontSize: "15px",
                fontWeight: "bold",
              }}
            >
              <span style={{ paddingLeft: "5%" }}>Dashboard</span>
            </Link>
            <span style={{ paddingLeft: "1%" }}>{`>`}</span>
            <span>Health Articles</span>
          </div>
          <div className="healtharticle-addnew ms-2">
            <div onClick={handleNewHealthArticle} className="addnewhealth">
              <div>
                <img src={add} alt="" />
              </div>
              <div>
                <button> Add New Health Articles</button>
              </div>
            </div>
            <div
              onClick={handleMenu}
              className="managehealth"
            //  style={{width:"40%"}}
            >
              <div>
                <button>Manage Menu & Category & Comments</button>
              </div>
              <div>
                <FaAngleRight />
              </div>
            </div>
            <div
              onClick={handleHealthsyStories}
              className="managehealth"
            >
              <div>
                <button>HealthSy Stories</button>
              </div>
              <div>
                <FaAngleRight />
              </div>
            </div>
          </div>
          <DoctorsFilterOptions
            limit={limit}
            sortshow={sortshow}
            sortName={sortName}
            search={search}
            showOption={true}
            setFilterShow={setFilterShow}
            setSortShow={setSortShow}
            onChange={onChangeSearch}
            setLimit={setLimit}
            filterBy={true}
          />
          <LoadingOverlay
            active={isLoading}
            spinner
            text="Loading..."
            styles={{
              overlay: (base) => ({
                ...base,
                backgroundColor: "transparent"
              }),
              spinner: (base) => ({
                ...base,
                '& svg circle': {
                  stroke: '#D5497C'
                }
              }),
              content: (base) => ({
                ...base,
                color: "#CB1B5B"
              })
            }}
          >
            <div className="healtharticle-table blog-table ms-2">
              <table>
                <thead>
                  <tr>
                    <td>S.No</td>
                    <td>Actions</td>
                    <td>Title</td>
                    <td>Menu</td>
                    <td>Category</td>
                    <td>Likes</td>
                    <td>Dislikes</td>
                    <td>Date of Publishing</td>
                    <td>Time</td>
                    <td>Trending</td>
                    <td>Spotlight</td>
                    <td>Featured Images</td>
                    <td>Status</td>
                  </tr>
                </thead>
                <tbody>
                  {records.map((el, i) => {
                    if (el.show === undefined || el.show === true)
                      return (
                        <tr key={el._id}>
                          <td>{skip + (i + 1)}</td>
                          <td>
                            <span
                              className="inactive-health"
                              onClick={() => {
                                handleActive(el._id, el.active);
                              }}
                            >
                              {el.active ? "Inactive" : "Active"}
                            </span>
                            <span className="inactive-icon">
                              <img src={inactive} alt="" />
                            </span>

                            <span
                              onClick={() => {
                                setId(el._id)
                                setDel(true);
                              }}
                              className="delete"
                              type="submit"
                            >
                              Delete
                            </span>
                            <span className="delete-icon"></span>

                            <span className="edit-health" onClick={() => handleEdit(el.slugurl)}>Edit</span>
                            <span className="edit-icon">
                              <img src={edit} alt="" />
                            </span>

                            <span
                              className="view"
                              onClick={() => handleView(el._id)}
                            >
                              View
                            </span>
                            <span className="view-icon"></span>
                          </td>
                          <td>{el.title}</td>
                          <td>{el.menu}</td>
                          <td>{el.category}</td>
                          <td>{el?.likes}</td>
                          <td>{el?.dislikes}</td>
                          <td>{Moment(el.createdAt).format("DD MMMM YYYY")}</td>
                          <td>{Moment(el.createdAt).format('LT')} (IST)</td>
                          <td>{el.trendingblog ? el.trendingblog : "-"}</td>
                          <td>{el.topicmonth ? "Yes" : "No"}</td>
                          <td>
                            <img src={featuredImage} alt="" />
                          </td>
                          <td>{el.active ? "Active" : "Inactive"}</td>
                        </tr>
                      );
                  })}
                </tbody>
              </table>
            </div>
          </LoadingOverlay>
        </div>
      </div>
      <div>
        <div className="mb-5 col d-flex pagination justify-content-end admin-pagination">
          <button
            className="page-link"
            onClick={() => handlePageChange({ selected: 0 })}
            disabled={skip === 0}
          >
            <AiOutlineBackward size={20} />
          </button>
          <ReactPaginate
            previousLabel="Previous"
            nextLabel="Next"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            pageCount={pages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName="pagination my-0"
            activeClassName="active"
            forcePage={pageOffset}
          />
          <button
            className="page-link"
            onClick={() => handlePageChange({ selected: pages - 1 })}
            disabled={(skip / limit) === (pages - 1)}
          >
            <AiOutlineForward size={20} />
          </button>
        </div>
      </div>

      {/*---------Filter Portion--------*/}
      {filtershow ? (
        <FilterRootModal
          filterOptions={filterBlogsOptions}
          onClickClear={() => {
            list_api_call(true);
            setFilterShow(false);
            setMenuData([]);
            setStatusData([]);
            setDatePublishing([]);
            setCategoryData([]);
            setTrendingBlog([]);
            setTopicOfMonth([]);
          }}
          onClickApply={() => {
            setFilterShow(false);
            list_api_call()
          }}
          selectOption={textcolor}
          setSelectOption={setTextColor}
        />
      ) : null}

      {textcolor === 1 && filtershow === true ? (
        <FilterModal
          title={"Menu"}
          data={menu}
          className="filterModelNestedLevel"
          activeFilters={menuData}
          onChange={(e) => {
            if (menuData.includes(e.target.name)) {
              setMenuData(menuData.filter((ele) => ele !== e.target.name))
            } else {
              setMenuData((pre) => [...pre, e.target.name])
            }
          }}
        />
      ) : null}

      {textcolor === 2 && filtershow === true ? (
        <FilterModal
          title={"Category"}
          data={category}
          isSearchable={true}
          className="filterModelNestedLevel filterOptionsModel"
          activeFilters={categoryData}
          searchQuery={searchQuery}
          onSearchChange={onSearchChange}
          onChange={onCategoryChange}
        />
      ) : null}

      {textcolor === 3 && filtershow === true ? (
        <FilterBooleanModal
          title={"Status"}
          data={statusValue.map((data) => data.label)}
          className="filter-doctor-fee-partnered-doctor"
          activeFilters={statusData}
          statusValue={statusValue}
          onChange={(e) => {
            const selectedValue = statusValue.find(item => item.label === e.target.name)?.value;

            if (statusData.includes(selectedValue)) {
              setStatusData(statusData.filter((ele) => ele !== selectedValue));
            } else {
              setStatusData((pre) => [...pre, selectedValue]);
            }
          }}
        />
      ) : null}

      {textcolor === 4 && filtershow === true ? (
        <FilterModal
          title={"Date of Publishing"}
          data={dateOfPublishing}
          className="filter-doctor-fee-partnered-doctor"
          activeFilters={datePublishing}
          onChange={(e) => {
            if (datePublishing.includes(e.target.name)) {
              setDatePublishing(datePublishing.filter((ele) => ele !== e.target.name))
            } else {
              setDatePublishing([e.target.name])
            }
          }}
        />
      ) : null}

      {textcolor === 5 && filtershow === true ? (
        <FilterModal
          title={"Trending"}
          data={trendingBlogs}
          className="filterModelNestedLevel"
          activeFilters={trendingBlog}
          clearBtn={true}
          clearBtnText={"Clear Trending"}
          onClickClear={handleClear}
          onChange={(e) => {
            if (trendingBlog.includes(e.target.name)) {
              setTrendingBlog(trendingBlog.filter((ele) => ele !== e.target.name))
            } else {
              setTrendingBlog((pre) => [...pre, e.target.name])
            }
          }}
        />
      ) : null}

      {textcolor === 6 && filtershow === true ? (
        <FilterBooleanModal
          title={"Spotlight"}
          data={spotlight.map((data) => data.label)}
          className="filter-doctor-fee-partnered-doctor"
          activeFilters={topicOfMonth}
          statusValue={spotlight}
          onChange={(e) => {
            const selectedValue = spotlight.find(item => item.label === e.target.name)?.value;

            if (topicOfMonth.includes(selectedValue)) {
              setTopicOfMonth(topicOfMonth.filter((ele) => ele !== selectedValue));
            } else {
              setTopicOfMonth((pre) => [...pre, selectedValue]);
            }
          }}
        />
      ) : null}

      {sortshow ? (
        <SortByModel
          latest="Latest"
          oldest="Oldest"
          handleSortClick={handleSortClick}
          selectedSort={selectedSort}
          textColor={textcolor}
          handleApplyClick={handleApplyClick}
        />
      ) : null}
      {
        del === true
        &&
        <Deletepopup
          handleDelete={handleDelete}
          id={id}
        />
      }
    </>
  );
};

export default HealthArticle;